import { render, staticRenderFns } from "./Plot.vue?vue&type=template&id=4635af01&scoped=true&"
import script from "./Plot.vue?vue&type=script&lang=js&"
export * from "./Plot.vue?vue&type=script&lang=js&"
import style0 from "./Plot.vue?vue&type=style&index=0&id=4635af01&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4635af01",
  null
  
)

export default component.exports